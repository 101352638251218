import React from 'react'
import { Helmet } from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'


class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const postLinks = posts.map((post) => (
      <Link to={post.node.fields.slug} key={post.node.fields.slug}>
        <div className="main-card">
          <div className="main-card-left-content">
            <div className="card-subtext">
              {post.node.frontmatter.titleInArticle.split(':')[1]}
            </div>
            <div className="card-maintext">
              <h2>{post.node.frontmatter.titleInArticle.split(':')[0]}</h2>
            </div>
          </div>
          <div className="main-card-right-content index">
          <PreviewCompatibleImage
                imageInfo={{
                  image: post.node.frontmatter.featuredimage,
                  alt: `Image pour l'article ${post.node.frontmatter.title}`,
                }}
              />
          </div>
        </div>
      </Link>
    ))
    const tag = this.props.pageContext.tag
    const title = this.props.data.site.siteMetadata.title

    return (
      <Layout>
        <Helmet title={`${tag} | ${title}`} />
        <header className="main-header front-header">
          <div className="main-header-text">
            <h1 className="main-h-text-1">{tag}</h1>
          </div>
        </header>
        <main className="category">
          <div className="breadcrumbs transparent">
            <div className="breadcrumb-links">
              <Link className="bread-item" to="/">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 22"><path d="M21 12v10h-6v-6H9v6H3V12H0L12 0l12 12zm-1-5.907V1h-3v2.093z"></path></svg>
              </Link>
               ❯
              <Link className="bread-item on" to="#">{tag}</Link>
            </div>
          </div>
          <div className="card-container">
            {postLinks}
          </div>
        </main>
      </Layout>
    )
  }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            titleInArticle
            slug
            featuredimage {
              childImageSharp {
                fixed (width:100, height: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
              extension
              publicURL
            }
          }
        }
      }
    }
  }
`
